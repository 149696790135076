import { disableIcons, enableIcons } from '@/api/pmicons';

const disableIcon = {
	id: 'disableIcon',
	selectionType: 'multiple',
	label: 'pmicons.actions.disableIcon',
	functionality: 'UPDATE_PMICONS',
	checkAvailability: function (icon) {
		return icon && icon.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmicons.actions.disableIcon');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmiconsid: registries[i].pmiconsid
			});
		}

		const data = await disableIcons(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableIcon = {
	id: 'enableIcon',
	selectionType: 'multiple',
	label: 'pmicons.actions.enableIcon',
	functionality: 'UPDATE_PMICONS',
	checkAvailability: function (icon) {
		return icon && icon.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmicons.actions.enableIcon');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmiconsid: registries[i].pmiconsid
			});
		}

		const data = await enableIcons(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

export default {
	actions: [disableIcon, enableIcon]
};
