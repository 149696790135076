// Aqui haremos las llamadas a todos los endpoints que cuelguen de eventtype
import Pui9HttpRequests from '@Pui9Requests';

export async function disableIcons(icon) {
	const controller = '/pmicons/disableIcons';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		icon,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function enableIcons(icon) {
	const controller = '/pmicons/enableIcons';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		icon,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}
